import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Process = makeShortcode("Process");
const Box = makeShortcode("Box");
const Link = makeShortcode("Link");
const CTA = makeShortcode("CTA");
const Accordion = makeShortcode("Accordion");
const List = makeShortcode("List");
const Tout = makeShortcode("Tout");
const Button = makeShortcode("Button");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`The CDIP Process`}</h1>
    <p>{`Here’s an outline of how self-directed projects move from `}<em parentName="p">{`idea`}</em>{` to `}<em parentName="p">{`approval`}</em>{` to `}<em parentName="p">{`completed`}</em>{`. We've added some helpful resources to get you started.`}</p>
    <p>{`At a high level, below is a visual representation of the current process.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Green:`}</strong>{` Proposal Owner`}</li>
      <li parentName="ul"><strong parentName="li">{`Purple:`}</strong>{` Approving Group`}</li>
      <li parentName="ul"><strong parentName="li">{`Orange:`}</strong>{` Both`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/images/CDIP-Contributor.png",
        "alt": "The Process for Getting a Community Project Started"
      }}></img></p>
    <h2>{`Roles`}</h2>
    <p>{`There are many roles involved in growing a community project from idea to reality.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Proposal Owner:`}</strong>{`  Creator of the proposal and the driver of the project. Leads meetings and communication, sets contributor roles, goals, timelines, and expectations.`}</li>
      <li parentName="ul"><strong parentName="li">{`Contributing Team:`}</strong>{` Contributes responsibilities or expertise to the owner, or lead`}</li>
      <li parentName="ul"><strong parentName="li">{`Advisor:`}</strong>{` Supportive role that can help scope, follow up, brainstorm, test, and keep Community Development abreast of communications and project updates.`}</li>
      <li parentName="ul"><strong parentName="li">{`Approving Group:`}</strong>{` Evaluators reviewing and approving proposals. A mix of Maker Foundation employees and seasoned contributors of the community.`}</li>
    </ul>
    <hr></hr>
    <h2>{`Process`}</h2>
    <Process mdxType="Process">
      <Box mdxType="Box">
        <h2>{`Write a Proposal From Our Template`}</h2>
        <p>{`A proposal should combine the suggested ideas and skills to improve resources for the MakerDAO community. Use this Google Doc template to structure the proposal so that information is clear and milestones are defined.`}</p>
        <Link to="https://docs.google.com/document/d/1lF8mNuomrguIS3lnvqTomS4NdON40nuyKRbLS6ZkMoA/edit" mdxType="Link">
  Open Proposal Template
        </Link>
      </Box>
      <Box mdxType="Box">
        <h2>{`Post Your Proposal in Our Chat`}</h2>
        <p>{`Post your proposal in our `}<Link to="https://chat.makerdao.com/channel/community-development" mdxType="Link">{`Community-Development Chat.`}</Link></p>
      </Box>
      <Box mdxType="Box">
        <h2>{`Engage With Feedback`}</h2>
        <p>{`An Advisor will reach out to help you through the next steps.`}</p>
        <p>{`The Approving Group will reach out to the proposal owner and provide feedback. You’ll work on the scope, budget, resources, and timeline.`}</p>
      </Box>
 
      <Box mdxType="Box">
        <h2>{`Wait for a Decision`}</h2>
        <p>{`An Approving Group meets weekly to review proposals. They will stay in touch for regular updates.`}</p>
        <p>{`The Approving Group discusses which proposals will get funding and which need more clarification. We’ll reach out to any proposal owners with the next steps and any comments.`}</p>
      </Box>
      <Box mdxType="Box">
        <h2>{`Start Work`}</h2>
        <p>{`If it’s a small or medium scoped project, and you’ve addressed any feedback, work can begin.`}</p>
        <p>{`For large projects, split proposals into GitHub issues/tasks. Another call with the approving group is needed to confirm the costs and resources.`}</p>
      </Box>
      <Box mdxType="Box">
        <h2>{`Communicate Progress`}</h2>
        <p>{`All projects will have checkpoints. You should expect to present progress at Community weekly sync meetings.`}</p>
      </Box>
      <Box mdxType="Box">
        <h2>{`Evaluate at a Retrospective`}</h2>
        <p>{`Upon completion, there is a review process. Share the work and discuss what went well, as well as what can be improved.`}</p>
      </Box>
    </Process>
    <CTA icon="question_mark" secondary mdxType="CTA">
      <p>{`If you are unsure about your proposal, post your idea in the chat for feedback. An Advisor will help you iterate on your project.`}</p>
    </CTA>
    <Accordion mdxType="Accordion">
      <h2><strong parentName="h2">{`DO`}</strong></h2>
      <ul>
        <li parentName="ul">{`Check `}<a parentName="li" {...{
            "href": "https://github.com/makerdao/community/issues?q=label%3ACDIP+"
          }}>{`existing or past proposals.`}</a></li>
        <li parentName="ul">{`Spend 1-3 hours on properly fleshing out your project.`}</li>
        <li parentName="ul">{`Bring friends and colleagues to help if necessary.`}</li>
        <li parentName="ul">{`Solicit feedback early and often.`}</li>
        <li parentName="ul">{`Have some idea about when to check in with the team and your CDIP's Advisor on progress and blockers.`}</li>
      </ul>
    </Accordion>
    <Accordion mdxType="Accordion">
      <h2><strong parentName="h2">{`DON'T`}</strong></h2>
      <ul>
        <li parentName="ul">{`Invoice for work before approval. You might not get paid for it.`}</li>
        <li parentName="ul">{`Submit more than one proposal at a time.`}</li>
        <li parentName="ul">{`Duplicate an existing proposal.`}</li>
        <li parentName="ul">{`Submit a proposal for small pieces of work.`}</li>
      </ul>
    </Accordion>
    <h2>{`Community Resources`}</h2>
    <p>{`Here’s a list of resources useful for executing on your proposal.`}</p>
    <List mdxType="List">
      <Link to="/contribute/content/writing-style-guide" mdxType="Link"> Writing Style Guide:
How to Write Like a Maker Contributor
      </Link>
      <Link to="/contribute/content/reviewer-guide" mdxType="Link"> Editorial Standards: What to Look Out for When Reviewing Work
      </Link>
      <Link to="/contribute/content/visual-style-guide" mdxType="Link"> Writing Style Guide: How to Look Like Comm-Dev at Maker
      </Link>
      <Link to="https://www.notion.so/makerdao/Maker-Brand-ac517c82ff9a43089d0db5bb2ee045a4" mdxType="Link">
 Assets and Design Files: Maker's official logos, badges, and product screenshots from the Foundation design team.
      </Link>
    </List>
    <h2>{`Proposal Details`}</h2>
    <p>{`Outline anything you need in your proposal. `}</p>
    <ul>
      <li parentName="ul">{`People Support: developers, designers, editors, writers, videographers`}</li>
      <li parentName="ul">{`Foundation Resources Support: brand assets, official product screenshots`}</li>
    </ul>
    <p>{`Let your Advisor know if you need more resources than you anticipated! They'll coordinate a meeting, outline any required resources, and present them to the Approving Group. They’ll figure out whether or not to approve additional resources.`}</p>
    <h2>{`What the CDIP Committee Looks For`}</h2>
    <ul>
      <li parentName="ul">{`Roadmap: Clear scope, milestones, timeline, and a path to delivery`}</li>
      <li parentName="ul">{`Duration: Three months or less`}</li>
      <li parentName="ul">{`Budget: Below 5K DAI`}</li>
      <li parentName="ul">{`Purpose: Clear impact on community growth, team, operations improvements, or workflow improvements`}</li>
      <li parentName="ul">{`Research: Complete research upfront to help the clarity of the plan`}</li>
    </ul>
    <h2>{`Current CDIP Decision Process`}</h2>
    <ul>
      <li parentName="ul">{`Advisor meeting complete, and table on second page complete`}</li>
      <li parentName="ul">{`Committee meets to determine if the impact is clear, and the ROI is defined`}</li>
      <li parentName="ul">{`Feedback is shared, if not approved, as well as the next steps for approval.`}</li>
      <li parentName="ul">{`Improvements shared and check-ins scheduled, if accepted`}</li>
      <li parentName="ul">{`The Advisor adds status updates and tags the GitHub issue`}</li>
    </ul>
    <h2>{`Quick Heuristics and Evaluative Questions`}</h2>
    <ul>
      <li parentName="ul">{`A simple set of deliverables; one or two items, a presentation, an MVP of a technical product, on a realistic timeline, is best.`}</li>
      <li parentName="ul">{`The budget is clear upfront; think defined number for set hours, try to avoid ranges.`}</li>
      <li parentName="ul">{`Does the contributor have a portfolio to cross-reference or demonstrate skills relevant to CDIP?`}</li>
      <li parentName="ul">{`Are the plans expedient?`}</li>
    </ul>
    <h2>{`Possible Blockers & Ways to Help`}</h2>
    <ul>
      <li parentName="ul">{`Longer than a three-month timeline`}</li>
      <li parentName="ul">{`More than five hours of research is necessary to complete the idea phase. The idea is probably not clear enough.`}</li>
      <li parentName="ul">{`Too many web3 interactions or protocol dependencies. A hackathon may be a better format.`}</li>
    </ul>
    <h2>{`Questions`}</h2>
    <p>{`Reach out to us on the community chat if you want to know more about submitting a community project idea.`}</p>
    <p><a parentName="p" {...{
        "href": "https://chat.makerdao.com/channel/community-development"
      }}>{`Community Chat`}</a></p>
    <h2>{`Submit a Proposal`}</h2>
    <Tout image mdxType="Tout">
      <p>{`Ready to share your idea with us? Submit a proposal and earn up to 5000 Dai.`}</p>
      <Button to="https://docs.google.com/document/d/1lF8mNuomrguIS3lnvqTomS4NdON40nuyKRbLS6ZkMoA/edit" mdxType="Button">
  Template
      </Button>
    </Tout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      